import React from 'react';
import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';

const COOKIE_NAME = 'googtrans';

/**
 * @typedef {Object} LanguageDescriptor
 * @property {string} name - The name of the language.
 * @property {string} title - The display title of the language.
 */

/**
 * @typedef {Object} GoogleTranslationConfig
 * @property {LanguageDescriptor[]} languages - The list of available languages.
 * @property {string} defaultLanguage - The default language.
 */

/**
 * @type {GoogleTranslationConfig}
 */

window.__GOOGLE_TRANSLATION_CONFIG__ = {
    languages: [
      { name: 'en', title: 'English' },
      { name: 'de', title: 'German' }
    ],
    defaultLanguage: 'en'
};  


const GoogleTranslate = () => {
  const [currentLanguage, setCurrentLanguage] = useState();
  const [languageConfig, setLanguageConfig] = useState();

  useEffect(() => {
    const cookies = new Cookies();
    const existingLanguageCookieValue = cookies.get(COOKIE_NAME);
    let languageValue;
    if (existingLanguageCookieValue) {
      const sp = existingLanguageCookieValue.split('/');
      if (sp.length > 2) {
        languageValue = sp[2];
      }
    }
    if (global.__GOOGLE_TRANSLATION_CONFIG__ && !languageValue) {
      languageValue = global.__GOOGLE_TRANSLATION_CONFIG__.defaultLanguage;
    }
    if (languageValue) {
      setCurrentLanguage(languageValue);
    }
    if (global.__GOOGLE_TRANSLATION_CONFIG__) {
      setLanguageConfig(global.__GOOGLE_TRANSLATION_CONFIG__);
    }
    if (window.TranslateInit) {
        window.TranslateInit();
    } else {
        console.error('TranslateInit function is not defined.');
    }

  }, []);

  if (!currentLanguage || !languageConfig) {
    return null;
  }

  const switchLanguage = (lang) => () => {
    const cookies = new Cookies();
    if(lang=='en'){
      cookies.remove(COOKIE_NAME); 
    }else{
      cookies.set(COOKIE_NAME, '/auto/' + lang);
    }
    window.location.reload();
  };

  return (
    <><Col lg={6} md={6}>
        <div className="language notranslate">
            <Icon icon="mdi-language" />
            <ul>
            {languageConfig.languages.map((ld, i) => (
                <React.Fragment key={`l_s_${ld.name}`}>
                {currentLanguage === ld.name ||
                (languageConfig.defaultLanguage === ld.name) ? (
                        <li><a href="#" onClick={switchLanguage(ld.name)} className={(currentLanguage === ld.name)?"active":''}>{ld.title}</a></li>
                ) : (
                    <li><a href="#" onClick={switchLanguage(ld.name)} className={(currentLanguage === ld.name)?"active":''}>{ld.title}</a></li>
                )}
                </React.Fragment>
            ))}
            {/* {languageConfig.languages.map((ld, i) => (
                <React.Fragment key={`l_s_${ld.name}`}>
                {currentLanguage === ld.name ||
                (currentLanguage === 'auto' &&
                    languageConfig.defaultLanguage === ld.name) ? (
                    <span className="mx-3 text-orange-300">
                    {ld.title}
                    </span>
                ) : (
                    <a
                    onClick={switchLanguage(ld.name)}
                    className="mx-3 text-blue-300 cursor-pointer hover:underline"
                    >
                    {ld.title}
                    </a>
                )}
                </React.Fragment>
            ))} */}
            </ul>
        </div>
    </Col></>
  );
};

export { GoogleTranslate, COOKIE_NAME };
